// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--open
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #5093b3 !important;
	border-color: #5093b3 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #7892b5 !important;
	border-color: #7892b5 !important;
	color: #fff !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action > i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px !important;
	height: 40px !important;
	width: 40px !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.btn-table-action-text {
	margin: 0px 3px 0px 3px !important;
	height: 40px !important;
}

.btn-table-action-text > span {
	margin: 0;
	padding: 0 !important;
}

.btn-table-action-small > i {
	width: 35px !important;
	font-size: 12px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
	padding: 0 !important;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #147dbf !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

body .p-button {
	margin: 0;
	background-color: #147dbf;
	border-radius: 0.25rem;
	border: none;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead > tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: 60px;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 1px solid #878691 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #878691 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #67666e !important;
}

.btn > i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.anexoBtnRemove {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.anexoBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.anexoContainer {
	margin-top: 15px;
	margin-bottom: 15px;
}

.kt-notification__item-icon > i {
	color: #147dbf !important;
}

body a:hover {
	color: #147dbf !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu > a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu > a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.btn-app-color {
	border-color: #147dbf !important;
	background-color: white !important;
	color: #147dbf !important;
}

.btn-app-color:hover {
	border-color: #147dbf !important;
	background-color: #147dbf !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.atalho-dashboard {
	background-color: #147dbf !important;
	color: white !important;
	height: 200px !important;
	font-weight: 600 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.atalho-dashboard > i {
	font-size: 4.5rem !important;
	margin: 0px 0px 25px 0px !important;
}

.atalho-dashboard > h3 {
	font-size: 1.8rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard {
	margin: 50px 60px 50px 60px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no-content-dashboard > i {
	font-size: 5rem !important;
	margin: 0px 0px 40px 0px !important;
}

.no-content-dashboard > h3 {
	font-size: 2.3rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard > p {
	margin-top: 10px !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
}

.item-atendimentos-hoje:not(:last-child) {
	margin-bottom: 2.3rem !important;
}

.item-atendimentos-hoje > .kt-timeline-v2__item-text {
	font-size: 1.2rem !important;
	padding: 0.2rem 0 0 5rem !important;
}

.nav-item.nav-link {
	padding: 15px 10px 15px 10px !important;
	color: #444 !important;
	border-radius: 0 !important;
}

.nav-item.nav-link:hover:not(.active) {
	color: #999 !important;
	border: 1px solid transparent !important;
}

.nav-item.nav-link.active {
	border-radius: 0px !important;
	border-top: 3px solid #147dbf !important;
	font-weight: 500 !important;
}

.nav-item.nav-link:first-child {
	border-left: 1px solid transparent !important;
}

.nav-tabs {
	border-bottom: 1px solid #ddd !important;
}

.tab-pane {
	padding: 0px 25px 25px 25px !important;
}

a.btn:hover {
	color: white !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #147dbf !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: rgba(20, 125, 191, 0.5) !important;
}

.MuiSwitch-thumb {
	box-shadow: none !important;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.tableHeaderItem {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.form-control:focus {
	border-color: #607d8b;
}

.btn.btn-label-brand {
	background-color: white !important;
	color: #147dbf !important;
}

.btn.btn-label-brand:hover,
.btn.btn-label-brand:focus {
	background-color: #147dbf !important;
	color: white !important;
}

.empty-icon {
	margin-top: 50px;
	font-size: 4.2rem;
}

.empty-text {
	margin: 50px 25px 50px 25px;
	font-size: 1.5rem;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.swal2-popup {
	border-radius: 15px !important;
}

.btn-filtros {
	margin-right: 5px;
}

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.buttonCenter {
	vertical-align: middle;
}

.formLogin {
	position: relative;
	z-index: 5;
	background-color: #ffff;
	max-width: 592px;
	width: 100%;
	min-width: 300px;
	margin-top: 56px;
	margin-bottom: 56px;
	padding: 30px 60px;
	border-radius: 16px;
}

.botaoEsqueceuSenha {
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: relative;
	width: 219px;
	height: 48px;
	background: #000000;
	border-radius: 4px;
	color: #ffffff;
	font-weight: bold;
	margin-top: 10px;

	&:hover {
		background-color: #282828 !important;
	}
}

.voltarAoLogin {
	color: #000000;
	margin-top: 10px;

	&:hover {
		color: #3a3838 !important;
	}
}

.button-adornment {
	border: none;
	background-color: transparent;
}

.linha-azul {
	background-color: #5b9bd5 !important;
	color: #000000;
	font-weight: 500;
}

.linha-azul-claro {
	background-color: #afd3ff !important;
	color: #000000;
}

.titulo-tooltip {
	font-weight: bold;
}

.nivel-box {
	background-color: #f0f0f0;
	padding: 10px;
	border-radius: 5px;
	font-weight: 500;
	text-align: center;
	color: #2f3435;
	margin: 0;
}

.title-modal {
	font-weight: 600;
	color: #2f3435;
	font-size: 15px;
	padding: 20px;
	border-bottom: 1px solid #ebedf2;
	width: 100%;
}

.title-dominio {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #212121;
}

.dominio {
	margin: -26px;
	margin-bottom: 0px;
	border-radius: 8px 8px 0px 0px;
	background: #969696;
	padding: 20px 24px;
}

.dominio p {
	margin-bottom: 0px;
}

.etapa,
.etapa-atual,
.definicao {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #212121;
}

.etapa {
	color: #e0e0e0;
}

.competencia {
	margin-bottom: 32px;
}

.competencia-pergunta {
	margin-bottom: 10px;
}

.title-competencia {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #212121;
}

.resposta-competencia {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #212121;
}

.crianca-dominio {
	margin: 10px 0px;
}

.crianca-dominio p {
	margin-bottom: 0px;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	color: #8e8e8e;
}

.crianca-dominio p:first-of-type {
	margin-bottom: 8px;
	font-weight: 400;
	color: #515151;
}

.text-dark {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #2f3435;
}

.text-dark-title {
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	color: #2f3435;
}

.circulo {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: #ffffff;
	color: #212121;
}

.icone-lixeira {
	color: #000000;
	background-color: #e2e2e2;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
	font-size: 20px;
	border-radius: 5px;
	cursor: pointer;
}

.react-calendar {
	max-width: 100%;
	background: white;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.react-calendar--doubleView {
	width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}

.react-calendar__navigation__next-button {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.react-calendar__navigation__prev-button {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation__arrow {
	color: #000 !important;
	font-size: 14px;
	font-weight: bold;
}

.react-calendar__navigation {
	display: flex;
	height: 44px;
	margin-bottom: 1em;
}

.react-calendar__navigation button {
	min-width: 44px;
	background: none;
}

.react-calendar__navigation__label__labelText {
	color: #2f3435;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	background: white;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #bcc2c4;
	text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
	border-bottom: 1px solid #e5e9ea;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
}

.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}

.react-calendar__tile {
	padding: 8px 8px;
	background: none;
	text-align: center;
	line-height: 16px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.react-calendar__tile > abbr {
	height: 30px;
	width: 30px;
	color: #2f3435;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}

.react-calendar__tile--now {
	background: #fff;
}

.react-calendar__tile--active:enabled:hover > abbr,
.react-calendar__tile--active:enabled:focus > abbr,
.react-calendar__tile--active > abbr {
	height: 30px;
	width: 30px;
	background-color: #147dbf;
	color: #fff;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}

.icone-seta {
	font-size: 14px;
}

.container-calendario {
	padding: 20px;
}

.marcador-calendario {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: #147dbf;
}

.competencia-calendario {
	padding: 14px;
	border-radius: 5px;
}

.title-header-plano {
	margin-bottom: 4px;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	color: #3a3838;
}

.subtitle-header-plano {
	margin-bottom: 0px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #444;
}

.adquirido {
	background-color: #abd7ff;
}

.em-aquisicao {
	background-color: #fcc797;
}

.nao-apresentado {
	background-color: #f7f7f7;
}

.nao-adquirido {
	background-color: #ffb8b8;
}

.adquirido-nivel {
	background-color: #abd7ff;
	color: #000;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin: 0px;
	height: 48px;
	min-width: 75px;
}

.em-aquisicao-nivel {
	background-color: #fcc797;
	color: #000;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin: 0px;
	height: 48px;
	min-width: 75px;
}

.nao-adquirido-nivel {
	background-color: #ffb8b8;
	color: #000;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin: 0px;
	height: 48px;
	min-width: 75px;
}

.nao-apresentado-nivel {
	background-color: #f7f7f7;
	color: #000;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin: 0px;
	height: 48px;
	min-width: 75px;
}

.bloco-niveis {
	display: flex;
	flex-direction: row;
	gap: 15px;
	overflow: auto;
}

.bloco-nivel {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100px;
}

.button-adornment {
	border: none;
	background-color: transparent;
}

.link {
	cursor: pointer;
}

.container-grafico-sucesso {
	position: relative;
	width: calc(100% - 20px);
	margin: auto;
}

.title-relatorio-oportunidade {
	position: absolute;
	margin-left: -20px;
	left: 0;
	top: 50%;
	transform: rotate(-90deg) translateX(-50%);
	transform-origin: top left;
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: #00a0fb;
}

.title-relatorio-sucesso {
	position: absolute;
	transform-origin: top right;
	right: 0;
	top: 50%;
	transform: rotate(-90deg) translateX(50%);
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: #fb7800;
}

.container-info-sucesso {
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	color: #595959;
	margin-bottom: 16px;
}

.container-info-sucesso p {
	margin-bottom: 4px;
}

.title-info-sucesso {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	padding: 14px 24px;
	background: #b8b8b8;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
}

.text-black {
	color: #000000;
}

.text-lower-80 {
	color: #f2b182;
}

.text-lower-60 {
	color: #c38f69;
}

.text-lower-40 {
	color: #965e36;
}

.container-legenda {
	float: right;
	width: 100%;
	max-width: 320px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
}

.container-legenda p {
	padding: 12px 40px;
	gap: 10px;
	border-radius: 4px;
}

.container-legenda .legenda {
	background-color: #b8b8b8;
}

.lower-80 {
	background-color: #f2b182;
}

.lower-60 {
	background-color: #c38f69;
}

.lower-40 {
	background-color: #965e36;
}

.dominio-nome {
	background-color: #fcc797;
	color: #000000;
	min-height: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: 600;
	padding: 5px;
}

.competencia-nome {
	background-color: #dbdbdb;
	color: #000000;
	min-height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 600;
	padding: 5px;
}

.etapa-nome {
	color: #000000;
	min-height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	margin: 20px 0px;
	padding: 5px;
}

.title-relatorio-etapa {
	position: absolute;
	margin-left: -20px;
	left: 0;
	top: 50%;
	transform: rotate(-90deg) translateX(-50%);
	transform-origin: top left;
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: #000000;
}

.campo-etapa {
	width: 100%;
	min-height: 40px;
	font-weight: 400;
	font-size: 15px;
	line-height: 24px;
	color: #212121;
}

.legenda-box {
	padding: 10px;
	border-radius: 5px;
	font-weight: 500;
	text-align: center;
	margin: 0;
	color: #2f3435;
}

.atraso-3 {
	color: #f2b182;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.atraso-6 {
	color: #c38f69;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.atraso-9 {
	color: #965e36;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.taxa-10 {
	color: #82f2f2;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.taxa-20 {
	color: #55bcbc;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.taxa-30 {
	color: #318585;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.container-niveis {
	max-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
}

.tabela-avaliacao td {
	min-width: 120px;
	max-width: 200px;
}

.p-datatable-tbody td {
	word-break: break-all;
	white-space: wrap;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 1024px) {
	.dominio {
		margin: -16px;
	}
}

@media only screen and (max-width: 600px) {
	.title-relatorio-oportunidade,
	.title-relatorio-sucesso {
		font-size: 14px;
	}
}

@media only screen and (max-width: 400px) {
	.title-relatorio-oportunidade,
	.title-relatorio-sucesso {
		font-size: 12px;
	}
}

@media print {
	.hide-print {
		display: none;
	}
}